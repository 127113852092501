import NewsletterForm from "../components/newsletter-form";
import {Helmet} from "react-helmet";

function TeaserPage() {
  return (
    <>
    <Helmet>
      <meta name="keywords" content="videogame, roleplaying, rpg, doom, death, metal, morbidware, stockholm, kartell" />
      <meta name="description" content="A new videogame by Morbidware in collaboration with Stockholm Kartell" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="The heresy is nigh" />
      <meta property="og:description" content="A new videogame by Morbidware in collaboration with Stockholm Kartell" />
      <meta property="og:url" content="https://morbidwaregames.com/" />
      <meta property="og:image" content="https://morbidwaregames.com/socialimage.jpg" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@HeresySupreme" />
      <title>The heresy is nigh</title>
    </Helmet>
    <div className="fixed bg-dark bg-animate w-screen h-screen top-0 left-0">
    </div>
    <div className="relative my-10 sm:my-0 sm:absolute sm:top-[calc(50%+100px)] sm:translate-y-[-50%] w-full">
      <div className="plate mx-auto bg-yellow text-center py-4 px-8 skew-y-2 z-20">
        <div className="container mx-auto">
          <p className="uppercase font-body text-3xl tracking-widest leading-8">
            Enlist as a{" "}
            <span className="normal-case font-serif font-bold text-4xl">
              Supreme Heretic
            </span>
            <br />
            and we'll chant to you
            <br />
            the{" "}
            <span className="font-mono font-bold text-red">
              Grim Calendar
            </span>
          </p>
          <div className="-skew-y-2">
            <NewsletterForm mode="light"></NewsletterForm>
          </div>
        </div>
      </div>
      <div className="relative container mx-auto my-10">
        <div className="flex flex-row flex-wrap justify-center mx-auto max-w-fit">
          <div className="basis-1/3 lg:basis-1/5 mb-4">
            <img
              src={require("../assets/images/morbidware_logo_y.png")}
              className="w-auto h-[70px] md:h-[120px] md:mx-2 object-cover mx-auto"
              alt="morbidware logo"
            />
          </div>
          <div className="basis-1/3 lg:basis-1/5 mb-4">
            <img
              src={require("../assets/images/avgrund_logo_y.png")}
              className="w-auto h-[70px] md:h-[120px] md:mx-2 object-cover mx-auto"
              alt="avgrund logo"
            />
          </div>
          <div className="basis-1/3 lg:basis-1/5 mb-4">
            <img
              src={require("../assets/images/morkborg_logo_y.png")}
              className="w-auto h-[70px] md:h-[120px] md:mx-2 object-cover mx-auto"
              alt="mork borg logo"
            />
          </div>
          <div className="basis-1/3 lg:basis-1/5 mb-4">
            <img
              src={require("../assets/images/oog_logo_y.png")}
              className="w-auto h-[70px] md:h-[120px] md:mx-2 object-cover mx-auto"
              alt="ockult ortmastare games logo"
            />
          </div>
          <div className="basis-1/3 lg:basis-1/5 mb-4">
            <img
              src={require("../assets/images/stockholmkartell_logo_y.png")}
              className="w-auto h-[70px] md:h-[120px] md:mx-2 object-cover mx-auto"
              alt="stockholm kartell logo"
            />
          </div>
        </div>
      </div>

      <div className="relative lg:mt-10 mx-auto text-center text-white pb-4 px-8 font-body z-10">
        <div className="container relative mx-auto">
          <p className="flex flex-col sm:flex-row z-10 gap-2 sm:gap-12 justify-center mb-5">
            <a href="https://www.iubenda.com/privacy-policy/18175297" target="_blank" rel="noreferrer" className="underline text-white hover:text-yellow">
              Privacy Policy
            </a>
            <a href="https://www.iubenda.com/privacy-policy/18175297/cookie-policy" target="_blank" rel="noreferrer" className="underline text-white hover:text-yellow">
              Cookie Policy
            </a>
            <a href="#" className="iubenda-cs-preferences-link underline text-white hover:text-yellow">
              Your Privacy Choices
            </a>
            <a href="#" className="iubenda-cs-uspr-link underline text-white hover:text-yellow">
              Notice at Collection
            </a>
          </p>
          <p className="relative z-10 mb-5">
            ©2024 Morbidware S.r.l. All rights reserved
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default TeaserPage;
