import { ChangeEvent, FormEvent, useState } from "react";
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import "../sections/newsletter.css";

export default function NewsletterForm(props: {mode?: 'dark'|'light'}) {
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [hasSubscriptionFailed, setHasSubscritpionFailed] = useState(false);

    const httpClient = axios.create({
        baseURL: process.env.REACT_APP_FLODESK_BASE_URL
    })

    function handleInput(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleSubmit(event: FormEvent) {
        event.preventDefault();

        setIsFormSubmitted(false);
        setHasSubscritpionFailed(false);

        if (email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
            setIsFormSubmitting(true);
            const submitPromise = getSubmitSubscriberPromise();
            if(submitPromise) {
                submitPromise.then(
                    (response) => {
                        if(response.status === 200) {
                            setIsFormSubmitting(false);
                            setEmail("");
                            setIsFormSubmitted(true);
                            setHasSubscritpionFailed(false);
                            console.log('Subscription success',response);
                        } else {
                            onSubscriptionFailed(response.data);
                        }
                    },
                    (error) => {
                        onSubscriptionFailed(error);
                    }
                );
            } else {
                onSubscriptionFailed('missing api key');
            }
        }

    }

    function onSubscriptionFailed(error: any) {
        setIsFormSubmitting(false);
        setEmail("");
        setIsFormSubmitted(true);
        setHasSubscritpionFailed(true);
        console.error('Subscription error', error);
    }

    function getSubmitSubscriberPromise() {
        const apiKey = process.env.REACT_APP_FLODESK_API_KEY;
        if(!apiKey) return null;
        
        const config: AxiosRequestConfig = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Basic ${btoa(apiKey)}`
            } as RawAxiosRequestHeaders,
        };
        const payload = { 
            email,
            segment_ids: ["66b34c337804d6b85869c5d7"]
        };
        return httpClient.post(`/subscribers`, payload, config); 
    }

    return (<form className="my-5" onSubmit={handleSubmit} id="subscription-form">
        <input type="text" name="email" id="email-input" placeholder="E-Mail" className="p-3 w-full max-w-[34rem] mb-3 text-center font-mono bg-gray-200 text-black"
            value={email} onChange={handleInput}/>
        <p className="font-body text-sm mb-2 -mt-2">
            Morbidware will be responsible for your personal data. For more information please check our 
            <a href="https://www.iubenda.com/privacy-policy/18175297" target="_blank" rel="noreferrer" 
            className={`underline ml-1 ${props.mode==="dark"?'text-white hover:text-yellow': 'text-black hover:text-magenta'}`}>
                Privacy Policy
            </a>
        </p>
        {!isEmailValid ? <p className="block mx-auto mb-3 text-red font-body">Please enter a valid email address</p> : null}
        {isFormSubmitted && !hasSubscriptionFailed? <p className={`block mx-auto mb-3 font-body text-2xl ${props.mode==="dark"?'text-yellow': 'text-black'}`}>You enrolled with success</p> : null}
        {isFormSubmitted && hasSubscriptionFailed? <p className={`block mx-auto mb-3 font-body text-2xl ${props.mode==="dark"?'text-red': 'text-black'}`}>Unfortunately your subscription failed. Please try again later.</p> : null}
        {
            !isFormSubmitting 
                ? <button type="submit" id="subscribe-button" className={`block mx-auto mb-3 font-body font-bold text-3xl hover:animate-fastBreathe text-magenta`}>
                    <span id="indicator" className="w-[60px] h-[30px] sm:w-[80px] sm:h-[40px]"></span>
                    Subscribe
                </button> 
                : <p className={`block mx-auto mb-3 font-body font-bol text-3xl animate-fastBreathe ${props.mode==="dark"?'text-magenta': 'text-black'}`}>Submitting...</p>
        }
    </form>);
}