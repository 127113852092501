import './App.css';
import TeaserPage from './pages/TeaserPage';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <>
      {process.env.REACT_APP_VARIANT === 'teaser' ? <TeaserPage/> : <LandingPage/>}
    </>
  );
}

export default App;
